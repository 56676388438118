<template>
  <v-container>
    <v-subheader>
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ $t('yhhd') }}</strong>
    </v-subheader>

    <v-row>
      <v-col
        v-for="(item, index) in list"
        :key="'promo_'+index"
        cols="12"
        md="6"
      >
        <v-card
          flat
          color="box_bg"
          @click="showdetail(item)"
        >
          <v-img :src="item.promo_banner">
            <v-chip
              v-if="index==0"
              dark
              color="green"
              class="float-right rounded-t-0 rounded-r-0"
            >
              NEW
            </v-chip>
          </v-img>
          <v-card-title class="text-body-1 overflow-x-visible">
            {{ item.promo_name }}
            <v-spacer />
            <v-btn
              icon
              absolute
              right
              class="mr-n4"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    
    <v-dialog
      v-model="detail"
      max-width="600"
      scrollable
      overlay-opacity="0.8"
    >
      <v-card color="box_bg">
        <v-img :src="chooseitem.promo_banner" />
        <v-card-title class="text-h6">
          {{ chooseitem.promo_name }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t('hdsj') }}: {{ chooseitem.start_time>0?$dayjs.unix(chooseitem.start_time).format('DD/MM/YYYY'):$dayjs().subtract(3, 'day').format('DD/MM/YYYY') }}
          ~ 
          {{ chooseitem.end_time>0?$dayjs.unix(chooseitem.end_time).format('DD/MM/YYYY'):$dayjs().add(3, 'day').format('DD/MM/YYYY') }}
        </v-card-subtitle>

        <v-card-text
          style="max-height: 30rem;"
          v-html="chooseitem.promo_content"
        />

        <v-divider class="opacity-3" />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="detail = false"
          >
            {{ $t('guanbi') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        detail: false,
        list: [],
        chooseitem: {}
      }
    },
  created() {
    this.promolist()
  },
  mounted() {

  },
  methods: {
    promolist() {
      let paramObj = {
        
      }
      this.$server.promolist(paramObj).then((response) => {
        if(response.code==200){
          this.list = response.data

          this.list.forEach( item => {
            if(item.id == this.$route.query.id){
              this.showdetail(item)
            }
          })
          
        }else{
          
        }
      })
    },
    showdetail(item){
      this.chooseitem = item
      this.detail = true
    }
  },
  }
</script>